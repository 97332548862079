<template>
  <div class="page users">
    <h1>Gebruikers</h1>
    <div class="page__content">
      <div class="data">
        <div class="data__controls">
          <button class="button button--accept" @click="addUser" ref="add_btn">
            Nieuwe gebruiker +
          </button>
        </div>
        <div class="data__table data__table--edit" v-if="users && users.length">
          <table>
            <thead>
              <tr>
                <th
                  v-for="item in Object.keys(users[0])"
                  :key="`user-head--${item}`"
                >
                  {{ item }}
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in users" :key="`user-item--${item.id}`">
                <td
                  v-for="key in Object.keys(item)"
                  :key="`user-item--${item.id}--${key}`"
                >
                  {{ item[key] }}
                </td>
                <td>
                  <div class="table__controls">
                    <span
                      class="md-24 material-icons"
                      :class="{
                        'md-disabled': item.id != currentUser && item.admin,
                      }"
                      @click="openUser({ ...item })"
                      ref="edit_btn"
                      >edit</span
                    >
                    <span
                      class="md-24 material-icons"
                      @click="sendInitPasswordLink(item.id)"
                      >key</span
                    >
                    <!--<span class="md-36 material-icons error" v-if="currentUser != item.id" @click="deleteId = item.id">delete</span>-->
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <transition name="fade">
            <Loader v-if="isLoading" />
          </transition>
        </div>
      </div>
    </div>
    <teleport to="#modals">
      <template v-if="activeUser">
        <focus-trap :active="activeUser">
          <div
            class="modal users__new"
            role="dialog"
            aria-labelledby="user-dialog"
          >
            <div class="modal__close" @click="closeUser">
              <span class="material-icons md-36">close</span>
            </div>
            <h2 id="user-dialog" class="modal__title">
              {{ activeUser.id ? "Wijzig Gebruiker" : "Nieuwe Gebruiker" }}
            </h2>
            <div class="modal__content">
              <form action="" @submit.prevent="" class="form">
                <div class="form__column">
                  <label>Voornaam</label>
                  <input
                    type="text"
                    class="input"
                    ref="fname"
                    v-model="activeUser.first_name"
                  />
                  <label>Naam</label>
                  <input
                    type="text"
                    class="input"
                    v-model="activeUser.last_name"
                  />
                  <label>Email (username)</label>
                  <input
                    type="email"
                    class="input"
                    v-model="activeUser.email"
                  />
                  <label>Tel</label>
                  <input type="tel" class="input" v-model="activeUser.phone" />
                  <label>{{
                    activeUser.id ? "Wijzig wachtwoord" : "Wachtwoord"
                  }}</label>
                  <input
                    class="input"
                    type="password"
                    autocomplete="new-password"
                    v-model="activeUser.password"
                  />
                  <label class="form__wide" for="isadmin"
                    ><span>Admin</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.admin"
                  /></label>
                  <label class="form__wide" for="isModerator"
                    ><span>Moderator</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.moderator"
                  /></label>
                  <label class="form__wide" for="isactief"
                    ><span>Actief</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.active"
                  /></label>
                  <label class="form__wide" for="isreadonly"
                    ><span>Readonly</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.readonly"
                  /></label>
                  <label class="form__wide"
                    ><span>Antwerpen</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.A"
                  /></label>
                  <label class="form__wide"
                    ><span>Gent</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.G"
                  /></label>
                  <label class="form__wide"
                    ><span>Zeebrugge</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.ZZ"
                  /></label>
                  <label class="form__wide"
                    ><span>Vlissingen</span
                    ><input
                      type="checkbox"
                      :true-value="1"
                      :false-value="0"
                      v-model="activeUser.V"
                  /></label>
                </div>
              </form>
            </div>
            <div class="modal__controls">
              <button
                class="button button--accept"
                :disabled="isLoading"
                @click.prevent="submitUser"
              >
                Opslaan
              </button>
            </div>
          </div>
        </focus-trap>
        <div class="modal__backdrop" @click="closeUser"></div>
      </template>
      <template v-if="deleteId">
        <focus-trap :active="deleteId">
          <div class="modal" role="dialog" aria-labelledby="user-delete-dialog">
            <div class="modal__close" @click="deleteId = null">
              <span class="material-icons md-36">close</span>
            </div>
            <h2 id="user-delete-dialog" class="modal__title">
              Verwijder Gebruiker
            </h2>
            <div class="modal__content" tabindex="-1">
              <p>
                Zeker dat je de gebruiker wenst te verwijderen?<br />Je kan
                gebruikers ook deactiveren door "actief" af te vinken.
              </p>
            </div>
            <div class="modal__controls">
              <button
                class="button button--cancel"
                :disabled="isLoading"
                @click.prevent="deleteUser"
              >
                Verwijderen
              </button>
            </div>
          </div>
        </focus-trap>
        <div class="modal__backdrop" @click="deleteId = null"></div>
      </template>
    </teleport>
  </div>
</template>

<script>
export default {
  name: "UserOverview",
  data() {
    return {
      isLoading: false,
      activeUser: null,
      deleteId: null,
      lastFocus: null,
    };
  },

  created() {
    this.getUsers();
  },
  computed: {
    currentUser() {
      return this.$store.getters["user/userData"]
        ? this.$store.getters["user/userData"].id
        : null;
    },
    users() {
      return this.$store.getters["user/users"];
    },
  },
  methods: {
    async getUsers() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("user/getUsers");
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    openUser(obj) {
      this.activeUser = obj;
      this.lastFocus = this.$refs.edit_btn;
      this.$nextTick(() => {
        this.$refs.fname.focus();
      });
    },
    addUser() {
      this.activeUser = {
        first_name: "",
        last_name: "",
        password: "",
        email: "",
        phone: "",
        admin: 0,
        active: 1,
        moderator: 0,
        readonly: 0,
        A: 1,
        G: 1,
        ZZ: 1,
        V: 1,
      };
      this.lastFocus = this.$refs.add_btn;
      this.$nextTick(() => {
        this.$refs.fname.focus();
      });
    },

    closeUser() {
      this.activeUser = null;
      this.lastFocus.focus();
      this.lastFocus = null;
    },

    async submitUser() {
      const isEdit = !!this.activeUser.id;
      const call = isEdit ? "user/editUser" : "user/addUser";
      if (!isEdit && !this.activeUser.password) {
        alert("Geef een wachtwoord op voor deze gebruiker.");
        return;
      }
      this.isLoading = true;
      try {
        await this.$store.dispatch(call, this.activeUser);
        this.activeUser = null;
        this.lastFocus.focus();
        this.lastFocus = null;
        this.getUsers();
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    async deleteUser() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("user/deleteUser", { id: this.deleteId });
        this.deleteId = null;
        this.getUsers();
      } catch (e) {
        alert(e);
      }
      this.isLoading = false;
    },

    async sendInitPasswordLink(id) {
      try {
        await this.$store.dispatch("user/sendInitPassword", { id });
        alert("Wachtwoord activatie-link succesvol verstuurd.");
      } catch (e) {
        alert(e);
      }
    },
  },
};
</script>
